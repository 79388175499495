import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import axiosInterceptor from "../../utils/axiosInterceptor.ts";

const BLANK_LOGIN = {
    email: "",
    password: "",
};

function LoginForm(props) {
    const navigate = useNavigate();
    const [login, setLogin] = React.useState(BLANK_LOGIN);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [errors, setErrors] = React.useState({
        email: false,
        password: false,
    });

    // Check if user is already logged in
    useEffect(() => {
        const isAuthenticated = !!localStorage.getItem("access_token");
        if (isAuthenticated) {
            // Already logged in, redirect to home
            navigate("/");
        }
    }, [navigate]);

    function handleChange(e) {
        setLogin({ ...login, [e.target.name]: e.target.value });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrorMessage("");

        const newErrors = {
            email: !login.email,
            password: !login.password,
        };
        setErrors(newErrors);

        // If there are any errors, do not proceed with the submission
        if (newErrors.email || newErrors.password) {
            setErrorMessage("Please fill in all required fields.");
            return;
        }
        const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1];
        try {
            const response = await axiosInterceptor.post(`/login/`, login, {
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": csrfToken,
                },
            });
            const refreshToken = response.data["refresh"];
            const accessToken = response.data["access"];
            const userID = response.data["user_id"];
            const userName =
                response.data["firstname"] + " " + response.data["lastname"];
            const is_email_verified = response.data["is_email_verified"];
            const is_verified_by_algolink =
                response.data["is_verified_by_algolink"];
            const is_algolink_admin = response.data["is_algolink_admin"];

            localStorage.setItem("access_token", accessToken);
            localStorage.setItem("refresh_token", refreshToken);
            localStorage.setItem("user_id", userID);
            localStorage.setItem("is_email_verified", is_email_verified);
            localStorage.setItem("is_algolink_admin", is_algolink_admin);
            localStorage.setItem(
                "is_verified_by_algolink",
                is_verified_by_algolink
            );
            localStorage.setItem("user_name", userName);
            navigate("../../");
            window.location.reload();
        } catch (error) {
            console.error("Error:", error);
            setErrorMessage(
                "Incorrect username or password. Please try again."
            );
            setErrors({ email: true, password: true });
        }
    };

    return (
        <Form
            onSubmit={handleSubmit}
            className="max-w-2xl flex flex-col mx-auto w-[370px] h-full justify-center -mt-8"
        >
            <div className="shadow-md border border-gray-300 rounded-lg p-4 flex flex-col gap-y-2">
                <h2 className="text-3xl font-medium text-left mb-3.5">
                    Welcome Back!
                </h2>
                <FloatingLabel label="Email">
                    <Form.Control
                        name="email"
                        size="sm"
                        type="email"
                        placeholder=" "
                        value={login.email}
                        onChange={handleChange}
                        isInvalid={errors.email}
                    />
                </FloatingLabel>
                <FloatingLabel label="Password">
                    <Form.Control
                        name="password"
                        type="password"
                        placeholder=" "
                        size="sm"
                        value={login.password}
                        onChange={handleChange}
                        isInvalid={errors.password}
                    />
                    <Link
                        className="text-sm text-[#1B4E99] !font-normal float-right mt-2 no-underline hover:text-[#1B4E99] hover:underline"
                        to={"../recover"}
                    >
                        Forgot password?
                    </Link>
                </FloatingLabel>
                {errorMessage && (
                    <small className="text-danger">{errorMessage}</small>
                )}
                {/* TODO: should eventually do some code cleanup — abstract this color button to a new react-bootstrap color. */}
                <Button
                    className="bg-[#1B4E99] text-white focus:bg-[#1B4E99] hover:bg-[#1B4E99] border-[#1B4E99] hover:border-[#1B4E99] font-medium text-sm px-4 py-2 rounded-lg shadow-sm mt-2.5"
                    type="submit"
                >
                    Login
                </Button>
                <p className="text-center text-sm !font-normal mt-1.5">
                    <span className="text-black">Don't have an account? </span>
                    <Link
                        className="text-sm text-[#1B4E99] no-underline hover:text-[#1B4E99] hover:underline"
                        to={"../signup"}
                    >
                        Sign up
                    </Link>
                </p>
            </div>
        </Form>
    );
}

export default LoginForm;
