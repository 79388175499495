import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import axiosInterceptor from "../../utils/axiosInterceptor.ts";
import { useDropzone } from "react-dropzone";
import sendVerificationCode from "../../utils/sendEmailVerification.ts";
import Spinner from "react-bootstrap/Spinner";

const BLANK_SIGNUP = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
};

function ResumeUpload({ onFileUpload, resumeFile }) {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            "application/pdf": [".pdf"],
            "application/msword": [".doc"],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
                ".docx",
            ],
        },
        onDrop: (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                onFileUpload(acceptedFiles[0]);
            }
        },
    });

    return (
        <div className="max-w-lg mx-auto w-full">
            <h2 className="text-base font-medium mb-3">Upload Resume</h2>
            <div
                {...getRootProps()}
                className="border !border-opacity-0 border-black bg-white rounded-lg p-8 text-center cursor-pointer"
            >
                <input {...getInputProps()} />
                {isDragActive ? (
                    <div>
                        <p>Drop the file here ...</p>
                        <p className="invisible h-0">
                            Drag and drop a file here or click the button below
                            to select a file to upload.
                        </p>
                    </div>
                ) : (
                    <p>
                        Drag and drop a file here or click the button below to
                        select a file to upload.
                    </p>
                )}
                <button
                    className="mt-4 px-4 py-2 bg-gray-200 rounded-md"
                    onClick={(e) => {
                        e.preventDefault();
                    }}
                >
                    Select File
                </button>
                {resumeFile && (
                    <div className="!mt-[1.5rem]">
                        <p>Selected file: {resumeFile.name}</p>
                    </div>
                )}
            </div>
        </div>
    );
}

function SignUp() {
    const navigate = useNavigate();
    const [signup, setSignup] = useState(BLANK_SIGNUP);
    const [errorMessage, setErrorMessage] = useState("");
    const [errors, setErrors] = useState({});
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [currentStep, setCurrentStep] = useState(1); // two steps total
    const [resumeFile, setResumeFile] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        const isAuthenticated = !!localStorage.getItem("access_token");
        if (isAuthenticated) {
            navigate("/");
        }
    }, [navigate]);

    function handleChange(e) {
        setSignup({ ...signup, [e.target.name]: e.target.value });
    }

    const handleResumeUpload = (file) => {
        setResumeFile(file);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrorMessage("");
        setIsSubmitting(true);

        // Validation checks
        const newErrors = {
            firstName: !signup.firstName,
            lastName: !signup.lastName,
            email: !signup.email,
            password: !signup.password,
            confirmPassword:
                !signup.confirmPassword ||
                signup.password !== signup.confirmPassword,
            agreeTerms: !agreeTerms,
        };
        setErrors(newErrors);

        if (Object.values(newErrors).some(Boolean)) {
            setErrorMessage("Please fill in all required fields correctly.");
            return;
        }

        if (!resumeFile) {
            setErrorMessage("Please upload a resume.");
            return;
        }

        try {
            // Step 1: Register user
            const userRegistrationData = {
                firstname: signup.firstName,
                lastname: signup.lastName,
                email: signup.email,
                password: signup.password,
                password_verify: signup.confirmPassword,
            };

            await axiosInterceptor.post(
                "/basic_register/",
                userRegistrationData
            );

            // Step 2: Log in user
            const loginData = {
                email: signup.email,
                password: signup.password,
            };

            const loginResponse = await axiosInterceptor.post(
                "/login/",
                loginData
            );

            // Extract tokens and user ID from login response
            const {
                refresh,
                access,
                user_id,
                is_email_verified,
                is_verified_by_algolink,
            } = loginResponse.data;

            // Store tokens and user info in localStorage
            localStorage.setItem("access_token", access);
            localStorage.setItem("refresh_token", refresh);
            localStorage.setItem("user_id", user_id);
            localStorage.setItem("is_email_verified", is_email_verified);
            localStorage.setItem("is_algolink_admin", false);
            localStorage.setItem(
                "is_verified_by_algolink",
                is_verified_by_algolink
            );
            localStorage.setItem(
                "user_name",
                `${signup.firstName} ${signup.lastName}`
            );

            // Step 3: Upload resume
            const formData = new FormData();
            formData.append("file", resumeFile);
            formData.append("root_directory", "resumes");

            await axiosInterceptor.post(`/file_upload/${user_id}/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            await sendVerificationCode(signup.email);

            // Navigate to verification page
            navigate("/account/verify");
        } catch (error) {
            console.error("Error:", error);
            // Go to previous step.
            setCurrentStep(1);

            if (error.response && error.response.data) {
                const errorData = error.response.data;
                let errorMessage = "";

                if (
                    Array.isArray(errorData) &&
                    errorData.length > 1 &&
                    typeof errorData[1] === "object"
                ) {
                    const errors = errorData[1];
                    for (const [field, messages] of Object.entries(errors)) {
                        errorMessage += `${field.charAt(0).toUpperCase() +
                            field.slice(1)}: ${messages.join(", ")}\n`;
                    }
                } else if (typeof errorData === "string") {
                    errorMessage = errorData;
                } else {
                    errorMessage =
                        "An unexpected error occurred during registration.";
                }

                setErrorMessage(errorMessage.trim());
            } else {
                setErrorMessage("An error occurred during registration.");
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Form
            onSubmit={handleSubmit}
            className="max-w-lg mx-auto flex flex-col h-full justify-center -mt-8"
        >
            {currentStep === 1 ? (
                <div className="w-[370px] flex flex-col gap-y-2">
                    <div className="shadow-md border border-gray-300 rounded-lg p-4 flex flex-col gap-y-2">
                        <h2 className="text-3xl font-medium text-left mb-3.5">
                            Sign Up
                        </h2>
                        <FloatingLabel label="First Name">
                            <Form.Control
                                name="firstName"
                                type="text"
                                placeholder=" "
                                value={signup.firstName}
                                onChange={handleChange}
                                isInvalid={errors.firstName}
                            />
                        </FloatingLabel>

                        <FloatingLabel label="Last Name">
                            <Form.Control
                                name="lastName"
                                type="text"
                                placeholder=" "
                                value={signup.lastName}
                                onChange={handleChange}
                                isInvalid={errors.lastName}
                            />
                        </FloatingLabel>

                        <FloatingLabel label="Email">
                            <Form.Control
                                name="email"
                                type="email"
                                placeholder=" "
                                value={signup.email}
                                onChange={handleChange}
                                isInvalid={errors.email}
                            />
                        </FloatingLabel>

                        <FloatingLabel label="Password">
                            <Form.Control
                                name="password"
                                type="password"
                                placeholder=" "
                                value={signup.password}
                                onChange={handleChange}
                                isInvalid={errors.password}
                            />
                        </FloatingLabel>

                        <FloatingLabel label="Confirm Password">
                            <Form.Control
                                name="confirmPassword"
                                type="password"
                                placeholder=" "
                                value={signup.confirmPassword}
                                onChange={handleChange}
                                isInvalid={errors.confirmPassword}
                            />
                        </FloatingLabel>

                        <Form.Check
                            type="checkbox"
                            label={
                                <div className="text-sm !font-normal mt-0.5">
                                    I agree to AlgoLink's{" "}
                                    <Link
                                        className="text-sm text-[#1B4E99] no-underline hover:text-[#1B4E99] hover:underline"
                                        target="_blank"
                                        to="/terms-of-use"
                                    >
                                        terms
                                    </Link>{" "}
                                    and{" "}
                                    <Link
                                        className="text-sm text-[#1B4E99] no-underline hover:text-[#1B4E99] hover:underline"
                                        target="_blank"
                                        to="/privacy-policy"
                                    >
                                        privacy policy
                                    </Link>
                                    .
                                </div>
                            }
                            checked={agreeTerms}
                            onChange={(e) => setAgreeTerms(e.target.checked)}
                            isInvalid={errors.agreeTerms}
                            className="mt-2"
                        />

                        {errorMessage && (
                            <small className="text-danger">
                                {errorMessage}
                            </small>
                        )}

                        <Button
                            className="bg-[#1B4E99] text-white focus:bg-[#1B4E99] hover:bg-[#1B4E99] border-[#1B4E99] hover:border-[#1B4E99] font-medium text-sm px-4 py-2 rounded-lg shadow-sm mt-2.5"
                            onClick={(e) => {
                                e.preventDefault();
                                setCurrentStep(2);
                            }}
                            disabled={
                                !agreeTerms ||
                                Object.values(errors).some(Boolean)
                            }
                        >
                            Next
                        </Button>
                    </div>
                </div>
            ) : (
                <div className="w-[370px] md:w-[570px]">
                    <div className="shadow-md border border-gray-300 rounded-lg p-4 flex flex-col gap-y-2">
                        <ResumeUpload
                            resumeFile={resumeFile}
                            onFileUpload={handleResumeUpload}
                        />
                        <div className="flex justify-between mt-4">
                            <Button
                                variant="ghost"
                                className="bg-transparent hover:bg-transparent text-[#1B4E99] hover:text-[#1B4E99] font-medium text-sm px-4 py-2 rounded-lg"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setCurrentStep(1);
                                }}
                            >
                                Back
                            </Button>
                            <Button
                                className="bg-[#1B4E99] text-white focus:bg-[#1B4E99] hover:bg-[#1B4E99] border-[#1B4E99] hover:border-[#1B4E99] font-medium text-sm px-4 py-2 rounded-lg shadow-sm"
                                type="submit"
                                disabled={
                                    Object.values(errors).some(Boolean) ||
                                    !resumeFile ||
                                    isSubmitting
                                }
                            >
                                {isSubmitting ? (
                                    <>
                                        <span className="mr-2">Signing Up</span>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </>
                                ) : (
                                    "Finish Signing Up"
                                )}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </Form>
    );
}

export default SignUp;
